import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { iOrderDocument } from './model/order-document.model'

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class DocumentsService {
    private getDocumentTypesURL = 'api/documentTypes';
    private downloadURL = "api/document";
    private documentCancelURL = "api/documentCancel";
    private documentDeleteURL = "api/documentDelete";

    constructor(
        private http: HttpClient,
    ) { }

    getDocumentTypes() {
        return this.http.get<any>(this.getDocumentTypesURL);
    }

    download(uuid: string) {
        const options: any = { observe: 'response' as 'body', responseType: 'blob' as 'blob' };
        return this.http.get<Blob>(`${this.downloadURL}/${uuid}`, options);
    }
    

    cancel(order) {
        return this.http.put<any>(this.documentCancelURL, { order: order }, httpOptions);
    }

    delete(orderId, documentId) {
        return this.http.post<any>(this.documentDeleteURL, { order_uuid: orderId, guid: documentId }, httpOptions);
    }

    private getDocumentsForOrderURL = 'api/getOrderDocuments';
    private getTemporaryDocumentsForOrderURL = 'api/getTemporaryOrderDocuments';
    private getDocumentsForSBLURL = 'api/getSBLDocuments';
    private getKCSDocumentTypesURL = 'api/kcsDocumentTypes';
    private filenameURL = "api/kcsFilename";
    private finishUploadURL = "api/kcsFinishUpload";
    private deleteTempFileURL = "api/kcsDeleteTempFile";
    private deleteOrderFileURL = "api/documentDelete";
    private updateDescriptionURL = "api/kcsUpdateDescription";
    private proxyURL = "api/getProxy";

    // addDocument(requestObject) {
    //     return this.http.put<any>(this.addDocumentURL, requestObject, httpOptions);
    // }

    getDocumentsForOrder(order_uuid) {
        return this.http.put<any>(this.getDocumentsForOrderURL, { order_uuid }, httpOptions);
    }

    getTemporaryDocumentsForOrder(order_uuid) {
        return this.http.put<any>(this.getTemporaryDocumentsForOrderURL, { order_uuid }, httpOptions);
    }

    getDocumentsForSBL(sbl, order_uuid, county) {
        return this.http.put<any>(this.getDocumentsForSBLURL, { sbl: sbl, order_uuid: order_uuid, county: county }, httpOptions);
    }

    getKCSDocumentTypes() {
        return this.http.get<any>(this.getDocumentTypesURL);
    }

    filename(guid) {
        return this.http.put<any>(this.filenameURL, { guid: guid }, httpOptions);
    }

    finishUpload(oid) {
        return this.http.put<any>(this.finishUploadURL, { oid: oid }, httpOptions);
    }

    deleteTempFile(guid) {
        return this.http.put<any>(this.deleteTempFileURL, { guid: guid }, httpOptions);
    }

    deleteOrderFile(guid, order_uuid) {
        return this.http.post<any>(this.deleteOrderFileURL, { guid, order_uuid }, httpOptions);
    }

    updateDescription(guid, description) {
        return this.http.put<any>(this.updateDescriptionURL, { guid: guid, description: description }, httpOptions);
    }

    getDeliveryEmailData(documentId) {
        return this.http.get<any>("api/deliveryEmailData/" + documentId, httpOptions);
    }

    sendDeliveryEmail(documentId, cc, additionalNotes, urlPrefix) {
        return this.http.post<any>("api/sendDeliveryEmail", {
            document_id: documentId,
            cc: cc,
            additional_notes: additionalNotes,
            url_prefix: urlPrefix,
        }, httpOptions);
    }

    getDocumentFromToken(token) {
        return this.http.get<any>("api/documentFromToken/" + token, httpOptions);
    }

    getProviderLogoFromToken(token) {
        return this.http.get<any>("api/providerLogoFromToken/" + token, httpOptions);
    }

    search(searchCriteria) {
        return this.http.put<any>("api/documentSearch",
            { searchCriteria }, httpOptions);
    }

    getOrderDocument(order_uuid: string) {
        return this.http.get<any>("api/getDocumentOrder/" + order_uuid, httpOptions)
    }

    // aarons code starts here

    getDocumentsByOrderUuid(orderUuid: string): Observable<iOrderDocument[]>
    {
        return this.http.get<any>("api/documentsByOrderUuid/" + orderUuid)
    }

    getFinalDocumentsByOrderUuid(orderUuid: string): Observable<iOrderDocument[]>
    {
        return this.http.get<any>("api/finalDocumentsByOrderUuid/" + orderUuid)
    }

    downloadDocument(documentUuid: string): Observable<Blob> 
    {
        return this.http.get("api/downloadDocument/" + documentUuid, { responseType: 'blob' });
    }
}
