import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocumentsService } from '../documents.service';
import { filter, map, catchError, throwError } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-document-table',
  templateUrl: './document-table.component.html',
  styleUrl: './document-table.component.css'
})
export class DocumentTableComponent implements OnInit {
  @Input() orderUuid: string;
  @Input() headers: string[] = [];
  @Input() data: any[] = [];

  @Output() deleteCompleted = new EventEmitter<void>();

  emailForm: FormGroup
  showEmailDialog: boolean = false;
  emailData: any;
  subject: string = '';
  toAddress: string = '';
  fileName: string = '';
  selectedRowData: any;
  
  constructor(
    private documentService: DocumentsService,
    private confirmationService: ConfirmationService, 
    private messageService: MessageService,
    private fb: FormBuilder
  ) {}

  download(doc)
  {
    const id = doc.uuid || doc.guid;
    if (!id) 
    {
      console.error("Document ID is missing");
      return;
    }
    console.log(id)
    this.documentService.download(id).pipe(
        filter(event => event instanceof HttpResponse),
        map((event: HttpResponse<Blob>) => event.body as Blob)
    ).subscribe({
      next: (body: Blob) => {
          if (!body) {
            console.error("No response body found");
            return;
          }
          const url = window.URL.createObjectURL(body);
          const link = document.createElement('a');

          link.href = url;
          link.download = doc.filename;
          link.click();

          window.URL.revokeObjectURL(url);
      },
      error: (error) => {
          console.error("Download failed", error);
      }
    });
  }

  downloadAll(docs: any[]) 
  {
    if (!docs || docs.length === 0) {
      console.error("No documents to download");
      return;
    }
    docs.forEach(doc => this.download(doc));
  }

  confirmDelete(event: Event, fileData: any)
  {
    console.log(fileData)
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Are you sure that you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      acceptIcon:"none",
      rejectIcon:"none",
      acceptButtonStyleClass:"p-button-danger",
      rejectButtonStyleClass:"p-button-text",
      accept: () => {
        this.deleteOrderFile(fileData)
      },
      reject: () => {
        this.messageService.add({ severity: 'error', summary: 'Canceled', detail: 'File Deletion Canceled', life: 3000 });
      }
    });
  }

  deleteOrderFile(doc) 
  {
    this.documentService.deleteOrderFile(doc.uuid, this.orderUuid).subscribe({
        next: (response) => {
          this.deleteCompleted.emit();
          this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'File Deleted' });
        },
        error: (error) => {
          console.error("An error occurred:", error);
        }
    });
  }

  openEmailDialog(document: any)
  {
    this.selectedRowData = null;
    this.getDeliveryEmailData(document.uuid).subscribe({
      next: (data) => {
        this.emailData = data;
        console.log("Email data fetched successfully", data);
        this.toAddress = this.emailData.client_user_email;
        this.fileName = document.filename;
        this.subject = this.emailData.provider_name + ' Documents - ' + this.emailData.address;
        this.initEmailForm();
      },
      error: (err) => {
        console.error("Error fetching email data", err);
      },
      complete: () => {
        console.log("Data fetching complete");
      }
    });
    this.selectedRowData = document;
    this.showEmailDialog = true;
  }

  closeEmailDialog()
  {
    this.showEmailDialog = false;
  }

  initEmailForm()
  {
    this.emailForm = this.fb.group({
      cc: ['', [Validators.email]],
      additionalNotes: ['']
    })
  }

  getDeliveryEmailData(document: any) 
  {
    return this.documentService.getDeliveryEmailData(document).pipe(
      map(response => {
          if (response.success) {
            return response.result[0];
          } else {
            throw new Error('Error while getting delivery email data');
          }
      }),
      catchError(err => {
        console.error("Error in fetching delivery email data", err);
        return throwError(err);
      })
    );
  }

  sendEmail() 
  {
    if (this.emailForm.valid) {
        const formValues = this.emailForm.value;
        const urlPrefix = window.location.origin;
        this.documentService.sendDeliveryEmail(
          this.selectedRowData.uuid,
          formValues.cc,
          formValues.additionalNotes,
          urlPrefix
        ).subscribe({
            next: (response) => {
                if (response.success) 
                {
                  this.closeEmailDialog()
                  this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Email Sent Successfully' });
                } else 
                {
                  this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to Send Email' });
                }
            },
            error: (err) => {
                console.error("Error sending email:", err);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'An error occurred while sending the email' });
            },
            complete: () => {
                console.log("Email sending process completed.");
            }
        });
    } else 
    {
      this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Please fill out the form correctly' });
    }
  }

  ngOnInit(): void {

  }
}
